import React from "react";
import './css/plugins/all.min.css';
import './css/plugins/flaticon.css';
import "./css/plugins/bootstrap.min.css"
import "./css/plugins/swiper-bundle.min.css"
import "./css/plugins/aos.css"
import "./css/plugins/magnific-popup.css"
import "./css/style.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Index5 from "./screens/index-5";
import ServiceComponent from "./screens/service";
import Team from "./screens/team";
import ContactPage from "./screens/contact";
import MainComponent from "./screens/about";
import Testimonial from "./screens/testimonial";
import  Pricing from "./screens/pricing";
import  Login from "./screens/login-register";
import OurWork from "./screens/our-work";

function App() {
  return (
    <Router>
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Index5 />} />
          <Route path="/about-us" element={<MainComponent />} />
           <Route path="/service" element={<ServiceComponent />} />
          <Route path="/team" element={<Team />} /> 
           {/* <Route path="/choose-us" element={<ChooseUs />} />  */}
          <Route path="/contact-us" element={<ContactPage />} /> 
          <Route path="/our-work" element={<OurWork />} /> 
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login-register" element={<Login />} />  
        </Routes>
        <Footer />
      </>
    </Router>
  );
}
export default App;