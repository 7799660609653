import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/all.css';
import img3 from '../images/bg/hero-bg1.png';
import img4 from '../images/hero-img1.png';
import img5 from '../images/ser-icon1.png';
import img6 from '../images/ser-icon2.png';
import img7 from '../images/ser-icon3.png';
import img8 from '../images/ser-icon4.png';
import img11 from '../images/bg/about-bg.png';
import img12 from '../images/about-1.jpg';
import img13 from '../images/about-2.jpg';
import img14 from '../images/counter-1.png';
import img15 from '../images/counter-2.png';
import img16 from '../images/counter-3.png';
import img17 from '../images/case-1.png';
import img19 from '../images/case-2.png';
import img21 from '../images/case-3.png';
import AOS from 'aos';
import React, { useEffect } from 'react';
import { Swiper } from 'swiper';
// import { Pagination } from 'swiper/modules';
// import SwiperCore   from 'swiper';
const ServiceSection = () => {
  return (
    <div class="section techwix-service-section section-padding-02">
      <div class="container">
        <div class="service-wrap">
          <div class="section-title text-center">
            <h3 class="sub-title">What we provide</h3>
            <h2 class="title">We provide truly prominent digital solutions.</h2>
          </div>
          <div class="service-content-wrap">
            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <div class="service-item service-01">
                  <div class="service-img">
                    <img src={img5} alt="" />
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <a href="service">Custom Software Solution</a>
                    </h3>
                    <p>
                      We specialize in building custom software solutions
                      tailored to our clients' specific needs. Whether it's web
                      applications, mobile apps, desktop software, or enterprise
                      solutions, we have the expertise to design and develop
                      bespoke software products from scratch.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="service-item">
                  <div class="service-img">
                    <img src={img6} alt="" />
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <a href="service">Business technology solution</a>
                    </h3>
                    <p>
                      We aims to leverage technology to improve business
                      processes, enhance efficiency, drive innovation, and
                      ultimately contribute to the organization's success. It
                      involves a holistic approach that considers not only the
                      technical aspects but also the business objectives and
                      organizational dynamics.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="service-item service-03">
                  <div class="service-img">
                    <img src={img7} alt="" />
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <a href="service">IT Consulting</a>
                    </h3>
                    <p>
                      We offer IT consulting to help our clients make informed
                      decisions about technology solutions. Whether it's
                      choosing the right technology stack, optimizing existing
                      systems, or strategizing for future growth, our team of
                      experts is here to help.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="service-item">
                  <div class="service-img">
                    <img src={img8} alt="" />
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <a href="service">Idea generate & solution</a>
                    </h3>
                    <p>
                      We aim to kickstart the software development process by
                      providing clients with a clear understanding of potential
                      solutions tailored to their needs, enabling them to make
                      informed decisions about their next steps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CaseStudy = () => {
  useEffect(() => {
    const swiper = new Swiper('.case-study-active', {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: false,
      loop: true,
      pagination: {
        el: '.case-study-active .swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
    console.log('Swiper instance:', swiper);
  }, []);

  return (
    // <!-- Case Study Start -->
    <div class="section techwix-case-study-section section-padding-02">
      <div class="container">
        <div class="case-study-wrap">
          <div class="section-title text-center">
            <h3 class="sub-title">Case studies</h3>
            <h2 class="title">Our projects make us proud</h2>
          </div>
          <div class="case-study-content-wrap">
            <div class="swiper-container case-study-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  {/* <!-- Single Case Study Start --> */}
                  <div class="single-case-study">
                    <div class="case-study-img">
                      <a
                        href="https://skupreme.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={img17}
                          alt="Skupreme"
                          style={{ height: '100%' }}
                        />
                      </a>
                    </div>
                    <div class="case-study-content">
                      <h3 class="title">
                        <a
                          href="https://skupreme.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Skupreme
                        </a>
                      </h3>
                      <p>E-commerce Supply Chain</p>
                    </div>
                  </div>
                  {/* <!-- Single Case Study End --> */}
                </div>
                <div class="swiper-slide">
                  {/* <!-- Single Case Study Start --> */}
                  <div class="single-case-study">
                    <div class="case-study-img">
                      <a
                        href="http://smok3.apim.us"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={img19}
                          alt="Smok3"
                          style={{ height: '100%' }}
                        />
                      </a>
                    </div>
                    <div class="case-study-content">
                      <h3 class="title">
                        <a
                          href="http://smok3.apim.us"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Game Metaverse
                        </a>
                      </h3>
                      <p>Smok3</p>
                    </div>
                  </div>
                  {/* <!-- Single Case Study End --> */}
                </div>
                <div class="swiper-slide">
                  {/* <!-- Single Case Study Start --> */}
                  <div class="single-case-study">
                    <div class="case-study-img">
                      <a
                        href="https://vulcanui.apim.us/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={img21} alt="Realios" />
                      </a>
                    </div>
                    <div class="case-study-content">
                      <h3 class="title">
                        <a
                          href="https://vulcanui.apim.us/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Property Management
                        </a>
                      </h3>
                      <p>Realios</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Add Pagination --> */}
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CounterSection = () => {
  return (
    // <!-- Counter Start -->
    <div class="section techwix-counter-section">
      <div class="container">
        <div class="counter-wrap">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              {/* <!-- Single Counter Start --> */}
              <div class="single-counter">
                <div class="counter-img">
                  <img src={img14} alt="" />
                </div>
                <div class="counter-content">
                  <span class="counter">12+</span>
                  <p>HAPPY CLIENTS</p>
                </div>
              </div>
              {/* <!-- Single Counter End --> */}
            </div>
            <div class="col-lg-3 col-sm-6">
              {/* <!-- Single Counter Start --> */}
              <div class="single-counter">
                <div class="counter-img">
                  <img src={img15} alt="" />
                </div>
                <div class="counter-content">
                  <span class="counter">40+</span>
                  <p>FINISHED PROJECTS</p>
                </div>
              </div>
              {/* <!-- Single Counter End --> */}
            </div>
            <div class="col-lg-3 col-sm-6">
              {/* <!-- Single Counter Start --> */}
              <div class="single-counter">
                <div class="counter-img">
                  <img src={img16} alt="" />
                </div>
                <div class="counter-content">
                  <span class="counter">10+</span>
                  <p>SKILLED EXPERTS</p>
                </div>
              </div>
              {/* <!-- Single Counter End --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
{
  /* const TestimonialSelection = () => {
  return (
    <div className="section techwix-testimonial-section section-padding-02">
      <div className="container">
        {/* Testimonial Wrap Start }
        <div className="testimonial-wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="testimonial-img">
                <div className="shape-1">
                  <img src={img24} alt="" />
                </div>
                <img src={img25} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial-content-wrap">
                <div className="swiper-container testimonial-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      {/* Single Testimonial Start }
                      <div className="single-testimonial">
                        <img src={img23} alt="" />
                        <p>
                          Accelerate innovation with world-class tech teams.
                          Beyond more stoic this along goodness hey this this
                          wow manatee
                        </p>
                        <span className="name">Mike Holder </span>
                        <span className="designation"> / CEO, Harlond inc</span>
                      </div>
                      {/* Single Testimonial End }
                    </div>
                    {/* Add more swiper slides if needed }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonial Wrap End }
      </div>
    </div>
  );
};
*/
}
{
  /*const BrandSection = () => {
  return (
    <div className="section techwix-brand-section">
      <div className="container">
          
        <div className="brand-wrapper section-padding text-center">
          <h3 className="brand-title">
            Step forward to become one of <span>49,494</span> successful clients
            of Mitech{' '}
          </h3>

          
        </div>
      </div>
    </div>
  );
}; 
*/
}
const Index5 = () => {
  useEffect(() => {
    // Initialize AOS on component mount
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <div>
      <div
        className="section techwix-hero-section"
        style={{ backgroundImage: `url(${img3})` }}
      >
        <div class="shape-2"></div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              {/* <!-- Hero Content Start --> */}
              <div class="hero-content">
                <h3 class="sub-title" data-aos-delay="600" data-aos="fade-up">
                  <span>HOT !</span> We can managed all digital services
                </h3>
                <h2 class="title" data-aos="fade-up" data-aos-delay="800">
                  {/* Your partner for digital solutions */}
                  Your Technology Partner for <br />
                  Software Innovation
                </h2>
                <p data-aos="fade-up" data-aos-delay="900">
                  We provide the most responsive and functional IT design for
                  companies and businesses worldwide.
                </p>
                <div class="hero-btn" data-aos="fade-up" data-aos-delay="1000">
                  <a class="btn" href="about">
                    Read More
                  </a>
                </div>
              </div>
              {/* <!-- Hero Content End --> */}
            </div>
            <div class="col-lg-7">
              {/* <!-- Hero Image Start --> */}
              <div class="hero-images">
                <div class="images">
                  <img src={img4} alt="" />
                </div>
              </div>
              {/* <!-- Hero Image ennd --> */}
            </div>
          </div>
        </div>
      </div>

      {/* Include the ServiceSection component */}
      <ServiceSection />
      {/* New Cta Section */}
      <div className="section techwix-cta-section section-padding">
        <div className="container">
          {/* <div className="cta-wrap">
            <div className="cta-icon">
              <img src={img9} alt="" />
            </div>
            <div className="cta-content text-center">
              <p>
                Stop wasting time and money on digital solutions.{' '}
                <a href="#">Let’s talk with us</a>
              </p>
            </div>
          </div> */}
        </div>
      </div>
      {/* End of Cta Section */}
      <>
        {/* About Section 1 */}
        <div
          className="section techwix-about-section section-padding"
          style={{ backgroundImage: `url(${img11})` }}
        >
          <div className="shape-1"></div>
          <div className="container">
            {/* About Wrapper Start */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* About Image Wrap Start */}
                  <div className="about-img-wrap">
                    <div className="about-img about-img-big">
                      <img src={img12} alt="" />
                    </div>
                    <div className="about-img about-img-sm">
                      <img src={img13} alt="" />
                      <div className="shape-01"></div>
                    </div>
                  </div>
                  {/* About Image Wrap End */}
                </div>
                <div className="col-lg-6">
                  {/* About Content Wrap Start */}
                  <div className="about-content-wrap">
                    <div className="section-title">
                      <h3 className="sub-title">Who we are</h3>
                      <h2 className="title">
                        Empowering Your Vision with Innovative Software
                        Solutions.
                      </h2>
                    </div>
                    <p>
                      At our software development company, we're dedicated to
                      bringing your unique vision to life through cutting-edge
                      technology and innovative solutions.
                    </p>
                    <p>
                      With our expertise and commitment to excellence, we
                      empower businesses like yours to thrive in today's digital
                      landscape. Whether you need custom software development,
                      web or mobile app solutions, or robust IT consulting,
                      we're here to help you achieve your goals and surpass your
                      expectations. Let's collaborate and turn your ideas into
                      reality.
                    </p>
                    {/*  <div className="play-btn">
                      <a
                        className="popup-video"
                        href="https://www.youtube.com/watch?time_continue=3&v=_X0eYtY8T_U"
                      >
                        <i className="fas fa-play"></i> <span>How we work</span>
                      </a>
                    </div> */}
                  </div>
                  {/* About Content Wrap End */}
                </div>
              </div>
            </div>
            {/* About Wrapper End */}
          </div>
        </div>
        {/* About Section 1 End */}
      </>
      <CounterSection />
      <CaseStudy />
      {/* <TestimonialSelection /> */}
      {/* <BrandSection /> */}
    </div>
  );
};

export default Index5;
