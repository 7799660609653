import React from 'react';
import img5 from '../images/banner.jpg';
import img2 from '../images/info-1.png';
import img3 from '../images/info-2.png';
import img4 from '../images/info-3.png';

const PageBanner = () => (
  <div
    className="section page-banner-section"
    style={{ backgroundImage: `url(${img5})` }}
  >
    <div class="shape-1">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
        <defs>
          <linearGradient
            id="PSgrad_0"
            x1="88.295%"
            x2="0%"
            y1="0%"
            y2="46.947%"
          >
            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          fill="rgb(43, 142, 254)"
          d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
        />
        <path
          fill="url(#PSgrad_0)"
          d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
        />
      </svg> */}
    </div>
    <div class="shape-2">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
        <path
          fill-rule="evenodd"
          stroke="rgb(255, 255, 255)"
          stroke-width="1px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          opacity="0.302"
          fill="none"
          d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z"
        />
      </svg> */}
    </div>
    <div class="shape-3">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
        <defs>
          <linearGradient
            id="PSgrad_0"
            x1="0%"
            x2="89.879%"
            y1="0%"
            y2="43.837%"
          >
            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          fill="rgb(43, 142, 254)"
          d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
        />
        <path
          fill="url(#PSgrad_0)"
          d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
        />
      </svg> */}
    </div>
    <div class="shape-4">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
        <path
          fill-rule="evenodd"
          stroke="rgb(255, 255, 255)"
          stroke-width="1px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="none"
          d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z"
        />
      </svg> */}
    </div>
    <div className="container">
      <div className="page-banner-wrap">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-banner text-center">
              <h2 className="title">Contact Us</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ContactPage = () => (
  <>
    <PageBanner />
    <ContactInfoSection />
    <ContactSection />
    <ContactMapSection />
  </>
);

const ContactInfoSection = () => {
  return (
    <div className="section contact-info-section section-padding-02">
      <div className="container">
        {/* Contact Info Wrap Start */}
        <div className="contact-info-wrap">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              {/* Single Contact Info Start */}
              <div className="single-contact-info text-center">
                <div className="info-icon">
                  <img src={img2} alt="" className="img-fluid" />
                </div>
                <div className="info-content">
                  <h5 className="title">Give us a call</h5>
                  <p>+91 89051 22725</p>
                  {/* <p>(+2) 500-950 456</p> */}
                </div>
              </div>
              {/* Single Contact Info End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Contact Info Start */}
              <div className="single-contact-info text-center">
                <div className="info-icon">
                  <img src={img3} alt="" className="img-fluid" />
                </div>
                <div className="info-content">
                  <h5 className="title">Drop us a line</h5>
                  <p>hardik@cynops.in</p>
                  {/* <p>info@techmaven.us</p> */}
                </div>
              </div>
              {/* Single Contact Info End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Contact Info Start */}
              <div className="single-contact-info text-center">
                <div className="info-icon">
                  <img src={img4} alt="" className="img-fluid" />
                </div>
                <div className="info-content">
                  <h5 className="title">Visit our office</h5>
                  <p style={{ whiteSpace: 'nowrap' }}>
                    Ahmedabad, Gujarat, India
                  </p>
                </div>
              </div>
              {/* Single Contact Info End */}
            </div>
          </div>
        </div>
        {/* Contact Info Wrap End */}
      </div>
    </div>
  );
};
const ContactSection = () => {
  return (
    <div className="section techwix-contact-section techwix-contact-section-02 techwix-contact-section-03 section-padding-02">
      <div className="container">
        {/* Contact Wrap Start */}
        <div className="contact-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-10"></div>
          </div>
        </div>
        {/* Contact Wrap End */}
      </div>
    </div>
  );
};

const ContactMapSection = () => {
  return (
    <div className="section contact-map-section">
      <div className="contact-map-wrap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d458.5668368608064!2d72.56124109761386!3d23.05717084336272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8483dd61da87%3A0x42012acd6313a63a!2s4%2C%20Partibha%20Park%20Ln%2C%20Shraddha%20Society%2C%20Hasmukh%20Colony%2C%20Rang%20Jyot%20Society%2C%20Naranpura%2C%20Ahmedabad%2C%20Gujarat%20380013!5e0!3m2!1sen!2sin!4v1708693418065!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
