import React, { useEffect } from 'react';
// import img1 from '../images//bg/page-banner.jpg';
import img1 from '../images/banner.jpg';
import skupreme from '../images/skupreme.png';
import Aos from 'aos';

const OurWork = () => {
  return (
    <>
      <div
        className="section page-banner-section"
        style={{ backgroundImage: `url(${img1})` }}
      >
        <div class="shape-1"></div>
        <div class="shape-2"></div>
        <div class="shape-4"></div>
        <div className="container">
          <div className="page-banner-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-banner text-center">
                  <h2 className="title">Our Work</h2>
                  <ul className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Our Work
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <BlogSection />
      </>
    </>
  );
};

export default OurWork;

const BlogSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    // <!-- Blog Start -->
    <div class="section techwix-blog-grid-section section-padding">
      <div class="container">
        <div class="techwix-blog-grid-wrap">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a
                    href="https://skupreme.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={skupreme} alt="" />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      Skurpeme
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    Skupreme is a supply chain management solution, listings
                    sync manager, and order fulfillment software all in one.
                    {/* At Northern Trust, our team is building solutions designed
                    and used for trading billions of dollars worth of securities
                    between the largest financial institutions internationally.
                    He is working with managers to identify and implement
                    various blockchain-based solutions in the Private Equity and
                    Security Landing spaces. */}
                  </p>{' '}
                  &nbsp;&nbsp;
                  {/* <p data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
                    Northern Trust is the 4th largest bank world wide in assets
                    under custody, and one of the main pillars of the global
                    banking system, with over $14 Trillion of assets under
                    custody.
                  </p> */}
                  {/* <h3 class="title"><a href="blog-details.html">How to become a successful businessman </a></h3> */}
                  {/* <div class="blog-btn">
                                    <a class="blog-btn-link" href="blog-details.html">Read Full <i class="fas fa-long-arrow-alt-right"></i></a>
                                </div> */}
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <iframe
                      frameborder="0"
                      allowfullscreen=""
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      title="BitRail Demo"
                      src="https://www.youtube.com/embed/Skj5Cbe1SRY?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;widget_referrer=https%3A%2F%2Ftechmaven.us%2F&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fon-chain.io&amp;widgetid=1"
                      id="widget2"
                    ></iframe>
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      End-to-end Supply Chain Management
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    {' '}
                    {/* BitRail is a blockchain infrastructure that enables entities
                    to operate and issue regulatory compliant cryptocurrencies
                    for payment within their own platforms. The BitRail platform
                    aims to enable compliant cryptocurrencies for commerce in
                    accordance with state money transmitter laws and FinCEN.
                    BitRail is currently in production, processing over $900M
                    worth of transactions annually, and serving over 5 million
                    active users. Tech Maven LLC is BitRail's strategic
                    technology partner. It was responsible for designing the
                    platform and building scalable blockchain sub-system and
                    implementing the integration with the main platform. */}
                    Every aspect of your ecommerce and supply chain activities,
                    integrated with all major shipping providers for streamlined
                    product stock levels.
                  </p>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/daikinlogo.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      Precise Inventory Predictions
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    {/* On Chain was contracted to build HyperLedger based supply
                    chain solution for Daikin. Daikin is $20B revenue Japanese
                    A.C. manufacturer. Our team was responsible for coding the
                    Hyperledger business network, designing the application and
                    helped coding Go chaincode. Let the team to build end to end
                    PoC. */}
                    All your data analyzed to predict and generate purchase
                    orders, eliminating the guesswork from ordering. We generate
                    all the documents your suppliers need, error-free.
                  </p>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    {' '}
                    <iframe
                      frameborder="0"
                      allowfullscreen=""
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      title="Questionnaire Connect"
                      src="https://www.youtube.com/embed/9go-0Xt33c4?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fon-chain.io&amp;widgetid=1"
                      id="widget2"
                    ></iframe>
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      The Solution to Point-Solutions
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    {/* Questionnaire Connect® provides a smart solution that allows
                  providers to seamlessly deliver a questionnaire to patients to
                  complete. Providers can track the progress of patients’ scores
                  and save that information for later review, copy it into EHR,
                  or print results in paper document. questionnaires can be
                  completed onsite or sent to patients via email or text
                  message. On Chain was contracted to build the platform end to
                  end. Platform is fully operational and in production in
                  multiple hospitals. questionnaireconnect.com  */}
                    SKUPREME is the most comprehensive solution for your
                    business, in the form of a global inventory and marketplace
                    integration software.
                  </p>
                  <div class="blog-btn">
                    <a class="blog-btn-link" href="">
                      Link to the Website{' '}
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/kodakone.png"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      KodakOne (Image Rights Management Platform)
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    KODAKOne is an innovative image licensing Platform that will
                    enable photographers to earn money from diverse revenue
                    streams and get paid faster. The platform intends to deliver
                    its immutable blockchain-enabled copyright protection
                    combined with a high-efficiency “trustless” transaction
                    platform for the assured licensing of rights-cleared and
                    protected digital assets. On Chain LLC was responsible for
                    building the blockchain sub-system and designing the
                    integration with the main platform.
                  </p>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/finwegoyellow.png"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      WeGO
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    WeGo is an On Chain Ride Share network. The startup is still
                    looking for strategic business partners.
                  </p>
                  <div class="blog-btn">
                    <a class="blog-btn-link" href="">
                      Link to the Website{' '}
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/finplaakred.png"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      Plaak
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    PLAAK is an all-inclusive, self-sustaining, and open
                    ecosystem. It significantly outperforms conventional Apps by
                    forming an ecosystem network that also links users to other
                    solutions and services in a one stop shop app. The PLAAK app
                    more importantly bridges the gap between fiat and digital
                    currencies, allowing users to use either or both across the
                    entire ecosystem. On Chain LLC is Plaak's Strategic
                    technology partner. It was responsible for building the
                    blockchain sub-system and designing the integration with the
                    main platform.
                  </p>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/bikechain_gs.png"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      BikeChain
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    Decentralized bike sharing network. The startup is still
                    looking for strategic business partners.
                  </p>
                  <div class="blog-btn">
                    <a class="blog-btn-link" href="">
                      Link to the Website{' '}
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <a href="">
                    <img
                      src="https://on-chain.io/wp-content/uploads/2023/06/blueprint.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      Your Project
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    Let us help you turn your idea into a working solution and
                    operating business. Schedule a call with us to discuss how
                    digital transformation can revolutionize your business.
                  </p>
                  <div class="blog-btn">
                    <a class="blog-btn-link" href="">
                      Schedule a call
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
            <div class="col-lg-6 col-md-6">
              {/* <!-- Single Blog Start --> */}
              <div class="single-blog">
                <div class="blog-image">
                  <video
                    className="video"
                    controls
                    playsInline
                    controlsList="nodownload"
                  >
                    <source
                      src="https://on-chain.io/wp-content/uploads/2023/07/nasa-intro.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <h2
                      className=""
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-once="true"
                    >
                      Our Projects
                    </h2>
                  </div>
                </div>
              </div>
              {/* <!-- Single Blog End --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
