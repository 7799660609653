import React from 'react';

import img1 from "../images/bg/page-banner.jpg";
import img2 from "../images/brand/brand-1.png";
import img3 from "../images/brand/brand-2.png";
import img4 from "../images/brand/brand-3.png";
import img5 from "../images/brand/brand-4.png";
import img6 from "../images/brand/brand-5.png";
import img7 from "../images/brand/brand-2.png";
import img8 from "../images/bg/cta-bg.jpg";
import img9 from "../images/cta-icon2.png";
// import { Pagination } from 'swiper/modules';
// import SwiperCore   from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const PageBanner = () => (
    <div className="section page-banner-section" style={{ backgroundImage: `url(${img1})` }} >
       <div className="shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>
  
                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                    <path fill="url(#PSgrad_0)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                </svg>
            </div>
            <div class="shape-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                </svg>
            </div>
            <div class="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>
  
                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                    <path fill="url(#PSgrad_0)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                </svg>
            </div>
            <div class="shape-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                </svg>
            </div>
      <div className="container">
        <div className="page-banner-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner text-center">
                <h2 className="title" >Pricing Table</h2>
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item" >
                   <a href="#">Home</a>
                     </li>
                    <li className="breadcrumb-item active" aria-current="page" >
                        Pricing Table
                    </li>
                     </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  
  const Pricing = () => (
    <>
    <PageBanner />
    <PricingSection />
    <BrandSection />
     <CtaSection />
  </>
  );
  
  const PricingSection = () => {
    return (
      <div class="section techwix-pricing-section section-padding-02">
            <div class="container">
                
                <div class="pricing-wrap">
                    <div class="section-title text-center">
                        <h3 class="sub-title">Pricing Plans</h3>
                        <h2 class="title">Affordable pricing for all</h2>
                    </div>
                    <div class="pricing-content-wrap">
                        <div class="row d-flex">
                            <div class="col-lg-4 col-md-6">
                                
                                <div class="single-pricing">
                                    <div class="pricing-badge">
                                        <span class="title">Free</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">0<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="#">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6">
                                
                                <div class="single-pricing active">
                                    <div class="pricing-badge">
                                        <span class="title">Starter</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">10<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="#">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6">
                                
                                <div class="single-pricing">
                                    <div class="pricing-badge">
                                        <span class="title">Pro</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">30<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="#">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        

    );
  };
  // SwiperCore.use([Pagination]);
  const BrandSection = () => {
    return (
      <div className="section techwix-brand-section techwix-brand-section-03">
      <div className="container">
        <div className="brand-wrapper text-center">
          <div className="brand-active">
            <Swiper
              spaceBetween={30}
              slidesPerView={5}
              loop={true}
              // pagination={{ clickable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 5,
                },

              }}
            >
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img2} alt="Brand" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img3} alt="Brand" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img4} alt="Brand" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img5} alt="Brand" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img6} alt="Brand" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide single-brand">
                  <img src={img7} alt="Brand" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
    );
  };

  const CtaSection = () => {
    return (
      <div className="section techwix-cta-section-02 section-padding-02" >
        <div className="container">
          <div className="cta-wrap" style={{ backgroundImage: `url(${img8})` }} >
            <div className="row align-items-center">
              <div className="col-xl-9 col-lg-8">
                <div className="cta-content">
                  <div className="cta-icon">
                    <img src={img9} alt="" />
                  </div>
                  <p>We’re Delivering the best customer Experience</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="cta-btn">
                  <a className="btn btn-white" href="#">+44 920 090 505</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Pricing;