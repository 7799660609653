import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import img from '../images/cynops-black-logo.svg';
const Header = () => {
  const location = useLocation();
  const isLinkActive = path => location.pathname === path;

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      if (window.scrollY > 50) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="header" className="section header-section">
      <div className="container">
        <div className="header-wrap">
          <div className="header-logo">
            <Link to="/">
              <img alt="" src={img} height={35} />
            </Link>
            {/* <a href="index-5"> */}
            {/* <Link to="/" style={{cursor: 'pointer' }}> Tech Maven </Link>  */}
            {/* </a> */}
          </div>
          <div className="header-menu d-none d-lg-block">
            <ul className="main-menu">
              <li className={isLinkActive('/') ? 'active' : ''}>
                {/* <a href="/">
                  {' '}
                  Home <span> </span>
                </a> */}
                <Link to="/">Home </Link>
              </li>
              <li className={isLinkActive('/about-us') ? 'active' : ''}>
                <Link to="/about-us"> About Us </Link>
              </li>
              {/* <li>
                <a href="#">
                  Pages <span className="menu-icon"></span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="/team">Our Team</a>
                  </li>
                  <li>
                    <a href="/service">Service</a>
                  </li>
                  <li>
                    <a href="/choose-us">Why Choose Us</a>
                  </li>
                  <li>
                    <a href="/testimonial">Testimonial</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/login-register">Login & Register</a>
                  </li>
                </ul>
              </li> */}
              <li className={isLinkActive('/service') ? 'active' : ''}>
                <Link to="/service"> Services </Link>
                {/*  <ul className="sub-menu">
                  <li>
                    <a href="/team">Software Development</a>
                  </li>
                  <li>
                    <a href="/testimonial">Mobile developement</a>
                  </li>
                </ul> */}
              </li>
              {/* <li className={isLinkActive('/our-work') ? 'active' : ''}>
                <Link to="/our-work"> Our Work </Link>
              </li> */}
            </ul>
          </div>
          <div className="header-meta">
            <div className="header-cart dropdown">
              {/* Your cart structure goes here */}
              {/* <button className="cart-btn" data-bs-toggle="dropdown">
                <FontAwesomeIcon icon={faShoppingCart} />
                <span className="count">0</span>
              </button> */}
            </div>
            {/* <div className="header-search"> */}
            {/* Your search structure goes here */}
            {/* <a className="search-btn" href="#"><FontAwesomeIcon icon={faSearch} /></a> */}
            <div className="search-wrap">
              <div className="search-inner">
                <i
                  id="search-close"
                  className="flaticon-close search-close"
                ></i>
                <div className="search-cell">
                  <form action="#">
                    <div className="search-field-holder">
                      <input
                        className="main-search-input"
                        type="search"
                        placeholder="Search Your Keyword..."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="header-btn d-none d-xl-block">
              <Link to="/contact-us" className="btn">
                {' '}
                Contact Us{' '}
              </Link>
            </div>
            {/* <!-- Header Toggle Start --> */}
            <div class="header-toggle d-lg-none">
              <button
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            {/* <!-- Offcanvas Start--> */}
            <div class="offcanvas offcanvas-start" id="offcanvasExample">
              <div class="offcanvas-header">
                {/* <!-- Offcanvas Logo Start --> */}
                <div class="offcanvas-logo">
                  <img alt="" src={img} />
                </div>
                {/* <!-- Offcanvas Logo End --> */}
                <button
                  type="button"
                  class="close-btn"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="flaticon-close"></i>
                </button>
              </div>
              {/* <!-- Offcanvas Body Start --> */}
              <div class="offcanvas-body">
                <div class="offcanvas-menu">
                  <ul class="main-menu">
                    <li class="active-menu">
                      <Link to="/">Home </Link>
                    </li>
                    <li className={location.pathname === '/about-us'}>
                      <Link to="/about-us"> About Us </Link>
                    </li>
                    <li className={location.pathname === '/service'}>
                      <Link to="/service"> Service </Link>
                    </li>
                    {/* <li className={location.pathname === '/our-work'}>
                      <Link to="/our-work"> Our Work </Link>
                    </li> */}
                    <li>
                      <Link to="/contact-us" className="btn">
                        {' '}
                        Contact Us{' '}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- Offcanvas Body End --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
