import React from 'react';
import img1 from '../images/cynops-white-Logo1024w.png';
import img2 from '../images/bg/footer-bg.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div
      className="section footer-section footer-section-03"
      style={{ backgroundImage: `url(${img2})` }}
    >
      <div className="container">
        <div className="footer-widget-wrap">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="footer-widget-about">
                <a className="footer-logo" href="/">
                  <img src={img1} alt="Logo" />
                </a>
                <p>
                  Empower your business with innovative technology solutions.
                  Our experts collaborate closely with you to drive success in
                  the digital age.
                </p>
                <div className="footer-social">
                  <ul className="social">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/cynops/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Useful Links</h4>
                <div className="widget-link">
                  <ul className="link">
                    {/* <li>
                      <Link to="/our-work">Our Work</Link>
                    </li> */}
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Contact Information</h4>
                <div className="widget-info">
                  <ul>
                    <li>
                      <div className="info-icon">
                        <i className="flaticon-phone-call"></i>
                      </div>
                      <div className="info-text">
                        <span>
                          <a href="tel:+918905122725">+91 89051 22725</a>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="info-icon">
                        <i className="far fa-envelope-open"></i>
                      </div>
                      <div className="info-text">
                        <span>
                          <a href="mailto:hardik@cynops.in">hardik@cynops.in</a>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="info-icon">
                        <i className="flaticon-pin"></i>
                      </div>
                      <div className="info-text">
                        <span>Ahmedabad, Gujarat - 380013 </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="copyright-text text-center">
                <p>Copyright @2022, Cynops All Rights Reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
