import React, { useState, useEffect } from 'react';
import img1 from '../images/banner.jpg';
import img2 from '../images/shape/about-shape2.png';
import img3 from '../images/about-3.jpg';
import img4 from '../images/about-4.jpg';
import img5 from '../images/bg/counter-bg2.jpg';
import img6 from '../images/bg/choose-us-bg3.jpg';
import img12 from '../images/ser-icon9.png';
import img13 from '../images/counter-3.png';
import img14 from '../images/ser-icon10.png';
import img15 from '../images/ser-icon12.png';
import img16 from '../images/counter-1.png';
import img17 from '../images/counter-2.png';
import img18 from '../images/ser-icon21.png';
import img19 from '../images/ser-icon22.png';
import img29 from '../images/bg/cta-bg.jpg';
import img30 from '../images/cta-icon2.png';
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';

const PageBanner = () => (
  <div
    className="section page-banner-section"
    style={{ backgroundImage: `url(${img1})` }}
  >
    <div class="shape-1">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
        <defs>
          <linearGradient
            id="PSgrad_0"
            x1="88.295%"
            x2="0%"
            y1="0%"
            y2="46.947%"
          >
            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          fill="rgb(43, 142, 254)"
          d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
        />
        <path
          fill="url(#PSgrad_0)"
          d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
        />
      </svg> */}
    </div>
    <div class="shape-2">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
        <path
          fill-rule="evenodd"
          stroke="rgb(255, 255, 255)"
          stroke-width="1px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          opacity="0.302"
          fill="none"
          d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z"
        />
      </svg> */}
    </div>
    <div class="shape-3">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
        <defs>
          <linearGradient
            id="PSgrad_0"
            x1="0%"
            x2="89.879%"
            y1="0%"
            y2="43.837%"
          >
            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          fill="rgb(43, 142, 254)"
          d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
        />
        <path
          fill="url(#PSgrad_0)"
          d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
        />
      </svg> */}
    </div>
    <div class="shape-4">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
        <path
          fill-rule="evenodd"
          stroke="rgb(255, 255, 255)"
          stroke-width="1px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="none"
          d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z"
        />
      </svg> */}
    </div>
    <div className="container">
      <div className="page-banner-wrap">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-banner text-center">
              <h2 className="title">About Us</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AboutSection = () => (
  <div className="section techwix-about-section-07 section-padding">
    <div className="shape-1"></div>
    <div className="container">
      <div className="about-wrap">
        <div className="row">
          <div className="col-lg-6">
            {/* About Image Wrap Start */}
            <div className="about-img-wrap">
              <img className="shape-1" src={img2} alt="" />
              <div className="about-img">
                <img src={img3} alt="" />
              </div>
              <div className="about-img about-img-2">
                <img src={img4} alt="" />
              </div>
            </div>
            {/* About Image Wrap End */}
          </div>
          <div className="col-lg-6">
            {/* About Content Wrap Start */}
            <div className="about-content-wrap">
              <div className="section-title">
                <h3 className="sub-title">Who we are</h3>
                <h2 className="title">
                  Empowering Your Vision with Innovative Software Solutions.
                </h2>
              </div>
              <p className="text">
                At our software development company, we're dedicated to bringing
                your unique vision to life through cutting-edge technology and
                innovative solutions. With our expertise and commitment to
                excellence, we empower businesses like yours to thrive in
                today's digital landscape. Whether you need custom software
                development, web or mobile app solutions, or robust IT
                consulting, we're here to help you achieve your goals and
                surpass your expectations. Let's collaborate and turn your ideas
                into reality.
              </p>
              {/* About List Start */}
              <div className="about-list-03">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="about-list-item-03">
                      <h3 className="title">Our Mission</h3>
                      <p>Transform Your vision into tangible reality.</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="about-list-item-03">
                      <h3 className="title">Custom Code</h3>
                      <p>
                        Accelerate innovation with world-class tech teams. We
                        help businesses elevate their value.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* About List End */}
            </div>
            {/* About Content Wrap End */}
          </div>
        </div>
      </div>
      {/* About Wrapper End */}
    </div>
  </div>
);
const MainComponent = () => (
  <>
    <PageBanner />
    <AboutSection />
    <CounterSection />
    <ChooseUsSection />
    <SkillSection />
    {/*<TeamSection />  */}
    {/*<TestimonialSection /> */}

    <CtaSection />
  </>
);

const CounterSection = () => (
  <div className="section techwix-counter-section-03 techwix-counter-section-04">
    <div className="container">
      <div className="counter-wrap" style={{ backgroundImage: `url(${img5})` }}>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            {/* Single Counter Start */}
            <div className="single-counter-02 text-center">
              <span>40+</span>
              <p>Project Complete</p>
            </div>
            {/* Single Counter End */}
          </div>
          <div className="col-lg-3 col-sm-6">
            {/* Single Counter Start */}
            <div className="single-counter-02 text-center">
              <span>12+</span>
              <p>Happy Clients</p>
            </div>
            {/* Single Counter End */}
          </div>
          <div className="col-lg-3 col-sm-6">
            {/* Single Counter Start */}
            <div className="single-counter-02 text-center">
              <span>100%</span>
              <p>Clients Satisfactions</p>
            </div>
            {/* Single Counter End */}
          </div>

          {/* Single Counter End */}
        </div>
      </div>
    </div>
  </div>
);

const ChooseUsSection = () => {
  return (
    <div
      className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding"
      style={{ backgroundImage: `url(${img6})` }}
    >
      <div class="container">
        {/* <!-- Choose Us Wrap Start --> */}
        <div class="choose-us-wrap">
          <div class="section-title text-center">
            <h3 class="sub-title">REASON TO CHOOSE US</h3>
            <h2 class="title">We provide truly prominent IT solutions.</h2>
          </div>
          <div class="choose-us-content-wrap">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img12} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">
                      Infrastructure <br /> Technology
                    </h3>
                    <p>
                      Empowering businesses with advanced infrastructure
                      technology.
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img13} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">Highly professional team members</h3>
                    <p>
                      Highly professional team members embody expertise and
                      seamless collaboration.
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img14} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">
                      Security <br /> Management
                    </h3>
                    <p>
                      Effective security management ensures resilience against
                      threats{' '}
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img15} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">
                      Desktop <br /> Computing
                    </h3>
                    <p>
                      Desktop computing remains a cornerstone of modern
                      productivity.
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img16} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">
                      System <br />
                      Integration <br />
                    </h3>
                    <p>Streamlining systems for enhanced workflow..</p>
                  </div>
                  {/* <!-- Choose Us Item End --> */}
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img17} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">Ideas for high return investment</h3>
                    <p>
                      Innovative ventures with robust potential for high
                      returns.
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img18} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">
                      Fully high IT <br /> Managment
                    </h3>
                    <p>
                      Comprehensive IT management ensures seamless operations.
                    </p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* <!-- Choose Us Item Start --> */}
                <div class="choose-us-item-02">
                  <div class="choose-us-img">
                    <img src={img19} alt="" />
                  </div>
                  <div class="choose-us-content">
                    <h3 class="title">Data secure & Managment</h3>
                    <p>Robust data security and management strategies.</p>
                  </div>
                </div>
                {/* <!-- Choose Us Item End --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Choose Us Wrap End --> */}
      </div>
    </div>
  );
};

const SkillSection = () => (
  <div className="section techwix-skill-section-02 techwix-skill-section-03 section-padding">
    <div className="container">
      <div className="skill-wrap">
        <div className="row">
          <div className="col-lg-6">
            {/* Skill Left Start */}
            <div className="skill-left">
              <div className="section-title">
                <h2 className="title">
                  Preparing for your <br /> success, we provide <br /> truly
                  prominent IT <br /> solutions
                </h2>
              </div>
              <div className="about-list">
                <ul>
                  <li>
                    <span className="about-icon">
                      <i className="fas fa-check"></i>
                    </span>
                    <span className="about-text">
                      We always focus on technical excellence{' '}
                    </span>
                  </li>
                  <li>
                    <span class="about-icon">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="about-text">
                      {' '}
                      Wherever you’re going, we bring ideas and excitement{' '}
                    </span>
                  </li>
                  <li>
                    <span class="about-icon">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="about-text">
                      We’re consultants, guides, and partners for brands{' '}
                    </span>
                  </li>

                  {/* Add more list items as needed */}
                </ul>
              </div>
              {/*  <div className="about-author-info-wrap">
                <div className="about-author">
                  <img src={img20} alt="" />
                  <h3 className="name">Alen Morno sin</h3>
                  <span className="designation">CEO, Techmax</span>
                </div>
                <div className="about-info">
                  <p>Call to ask any question </p>
                  <h3 className="number">0123-456-7890</h3>
                </div>
              </div> */}
            </div>
            {/* Skill Left End */}
          </div>
          <div className="col-lg-6">
            {/* Skill Right Start */}
            <div className="skill-right">
              <p className="text">
                We provide the most responsive and functional IT design for
                companies and businesses worldwide.
              </p>
              <div className="counter-bar">
                {/* Skill Item Start */}
                <SkillItem title="IT Managment" width="80" />
                {/* Add more SkillItem components for each item */}
              </div>
            </div>
            {/* Skill Right End */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SkillItem = () => (
  <div className="skill-item">
    <ProgressBarWithAnimation title="Web Development" percentage={95} />
    <ProgressBarWithAnimation title="Mobile Development" percentage={90} />
    <ProgressBarWithAnimation title="Blockchain Development" percentage={80} />
    <ProgressBarWithAnimation title="UI/UX" percentage={85} />
    <ProgressBarWithAnimation title="AI" percentage={70} />
    <ProgressBarWithAnimation title="IT Management" percentage={80} />
  </div>
);

const ProgressBarWithAnimation = ({ title, percentage }) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    if (!displayed) {
      const timeoutId = setTimeout(() => {
        setDisplayed(true);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [displayed]);

  return displayed && <ProgressBar title={title} percentage={percentage} />;
};

const ProgressBar = ({ title, percentage }) => (
  <>
    <span className="title">{title}</span>
    <div className="skill-bar">
      <div className="bar-inner">
        <div
          className="bar progress-line color-1"
          style={{ width: `${percentage}%` }}
        >
          <span className="skill-percent">
            <span className="counter">{percentage}%</span>
          </span>
        </div>
      </div>
    </div>
  </>
);

{
  /* const TeamSection = () => (
  <div
    className="section techwix-team-section techwix-team-section-02 section-padding"
    style={{ backgroundImage: `url(${img7})` }}
  >
    <div className="container">
      {/* Team Wrap Start /}
      <div className="team-wrap">
        <div className="section-title text-center">
          <h3 className="sub-title">Our expert team </h3>
          <h2 className="title white"> We have a world-class expert team</h2>
        </div>
        {/* Team Content Wrap Start /}
        <div className="team-content-wrap">
          <div className="swiper-container team-active">
            <div className="swiper-wrapper">
              {/* Add more swiper-slide components for each team member /}
              <TeamMemberSlide
                name="Andrew Max Fetcher"
                designation="CEO, techwix"
                imgSrc={img8}
              />
              <TeamMemberSlide
                name="Arnold human"
                designation="CEO, techwix"
                imgSrc={img9}
              />
              <TeamMemberSlide
                name="Mike Holder"
                designation="CEO, techwix"
                imgSrc={img10}
              />
              <TeamMemberSlide
                name="Joakim Ken"
                designation="CEO, techwix"
                imgSrc={img11}
              />
            </div>
            {/* Add Pagination /}
            <div className="swiper-pagination"></div>
          </div>
        </div>
        {/* Team Content Wrap End /}
      </div>
      {/* Team Wrap End /}
    </div>
  </div>
);

const TeamMemberSlide = ({ name, designation, imgSrc }) => {
  return (
    <div className="swiper-slide">
      {/* Single Team Start /}
      <div className="single-team">
        <div className="team-img">
          <a href="team">
            <img src={imgSrc} alt="" />
          </a>
        </div>
        <div className="team-content">
          <h3 className="name">
            <a href="team">{name}</a>
          </h3>
          <span className="designation">{designation}</span>
          <div className="team-social">
            <ul className="social">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-dribbble"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-behance"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Single Team End /}
    </div>
  );
}; */
}
SwiperCore.use([Pagination]);
{
  /* const TestimonialSection = () => (
  <>
    {/* <!-- Testimonial Start  --> /}
    <div class="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding-02">
      <div class="container">
        <div class="testimonial-wrap-02">
          <div class="section-title text-center">
            <h3 class="sub-title">Testimonial</h3>
            <h2 class="title">20k+ satisfied clients worldwide</h2>
          </div>
          <div class="testimonial-content-wrap-02">
            <div class="swiper-container testimonial-02-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div className="testimonial-content-wrap-02">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={2}
                      loop={true}
                      pagination={{ clickable: true }}
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        576: { slidesPerView: 1 },
                        768: { slidesPerView: 1 },
                        992: { slidesPerView: 1 },
                        1200: { slidesPerView: 2 },
                      }}
                    >
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={img21} alt="" />
                          </div>
                          <div className="testimonial-content">
                            <img src={img22} alt="" />
                            <p>
                              Accelerate innovation with world-class tech teams
                              Beyond more stoic this along goodness hey this
                              this wow manatee
                            </p>
                            <span className="name">Mike Holder </span>
                            <span className="designation">
                              {' '}
                              / CEO, Harlond inc
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={img21} alt="" />
                          </div>
                          <div className="testimonial-content">
                            <img src={img22} alt="" />
                            <p>
                              Accelerate innovation with world-class tech teams
                              Beyond more stoic this along goodness hey this
                              this wow manatee
                            </p>
                            <span className="name">Mike Fermalin </span>
                            <span className="designation">
                              {' '}
                              / CEO, Harlond inc
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={img21} alt="" />
                          </div>
                          <div className="testimonial-content">
                            <img src={img22} alt="" />
                            <p>
                              Accelerate innovation with world-class tech teams
                              Beyond more stoic this along goodness hey this
                              this wow manatee
                            </p>
                            <span className="name">Mike Holder </span>
                            <span className="designation">
                              {' '}
                              / CEO, Harlond inc
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>

              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- Testimonial End  --> /}
  </>
);
*/
}

// const BrandLogoSlide = ({ imageSrc, alt }) => {
//   return (
//     <div className="swiper-slide single-brand">
//       {/* Single Brand Start */}
//       <img src={imageSrc} alt={alt} />
//       {/* Single Brand End */}
//     </div>
//   );
// };

const CtaSection = () => {
  return (
    <div className="section techwix-cta-section-02 section-padding-02">
      <div className="container">
        <div className="cta-wrap" style={{ backgroundImage: `url(${img29})` }}>
          <div className="row align-items-center">
            <div className="col-xl-9 col-lg-8">
              <div className="cta-content">
                <div className="cta-icon">
                  <img src={img30} alt="" />
                </div>
                <p>We’re Delivering the best customer Experience</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="cta-btn">
                <a className="btn btn-white" href="#">
                  +91 89051 22725
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainComponent;
